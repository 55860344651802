var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_predicates),expression:"is_predicates"}]},[_c('v-card',{staticClass:"d-flex justify-start align-self-center align-content-center align-center",attrs:{"color":"transparent","elevation":"0"}},[_c('CardSwitch',{attrs:{"customClass":"","reference":"targeting_predicate_id","inset":false,"true_value":_vm.predicates.INCLUDED,"false_value":_vm.predicates.EXCLUDED,"label":_vm.targeting_predicate_id === _vm.predicates.INCLUDED
						? 'Include'
						: 'Exclude',"color":_vm.targeting_predicate_id === _vm.predicates.INCLUDED
						? 'success'
						: 'red',"disabled":!_vm.time.user_time_of_week.targeting_terms.length,"readonly":_vm.readonly},model:{value:(_vm.targeting_predicate_id),callback:function ($$v) {_vm.targeting_predicate_id=$$v},expression:"targeting_predicate_id"}})],1)],1),_c('v-list-item',[_c('v-card',{staticClass:"d-flex justify-start align-self-center align-content-center align-center my-4",attrs:{"color":"transparent","elevation":"0"}},[_c('v-simple-table',{staticClass:"align-center",attrs:{"color":"transparent","elevation":"0","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticClass:"align-center text-center"},[_c('tr',{staticClass:"no-striped align-center"},_vm._l((_vm.headers),function(item){return _c('th',{key:item.key,staticClass:"no-sticky align-center tableFrecuency"},[_c('span',{staticClass:"align-center"},[_vm._v(" "+_vm._s(item.display)+" ")])])}),0)]),_c('tbody',_vm._l((_vm.items),function(i){return _c('tr',{key:i.key},[_c('td',{staticClass:"tableFrecuency",attrs:{"color":"tertiary"}},[_c('strong',{staticClass:"tertiary--text"},[_vm._v(" "+_vm._s(_vm.$t(i.value))+" ")])]),_vm._l((i.rows),function(r){return _c('td',{key:r.key,staticClass:"justify-center align-center ma-0 pa-0 mx-auto pointer no-sticky tableFrecuency",attrs:{"color":"tertiary"},on:{"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.updateTargetingChecked(r, i.value)},"mouseover":function($event){if(!$event.ctrlKey)return null;return _vm.updateTargetingChecked(r, i.value)}}},[_c('v-card',{staticClass:"d-flex justify-center align-center ma-0 pa-4 item",class:_vm.hasTargetingChecked(r)
											? _vm.getColorGrid()
											: 'white--text',staticStyle:{"width":"100%"},attrs:{"color":_vm.hasTargetingChecked(r)
											? _vm.getColorGrid()
											: 'white',"elevation":_vm.hasTargetingChecked(r) ? 3 : 1,"outlined":"","tile":""}},[_vm._v(" "+_vm._s(r.key)+" ")])],1)})],2)}),0)]},proxy:true}])})],1)],1),_c('v-list-item',[_c('v-card',{staticClass:"d-flex justify-center",attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"font-italic secondary--text"},[_c('strong',[_vm._v(_vm._s(_vm.$t("targeting.time.note")))]),_vm._v(": "+_vm._s(_vm.$t("targeting.time.note_details"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }