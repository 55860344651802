var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"formVideo",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.CompanionSupports,"hint":"","label":_vm.$t('modifier.labels.video.companion_support.label'),"placeholder":_vm.$t('modifier.labels.video.companion_support.label'),"error_messages":_vm.getError('companion_support'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchSupports()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelCompanionSupport)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.video.companion_support.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueCompanionSupport),callback:function ($$v) {_vm.valueCompanionSupport=$$v},expression:"valueCompanionSupport"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.VideoPlaybackMethods,"hint":"","label":_vm.$t('modifier.labels.video.playback_method.label'),"placeholder":_vm.$t('modifier.labels.video.playback_method.label'),"error_messages":_vm.getError('playback_method'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchPlaybackMethods()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelPlaybackMethod)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.video.playback_method.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valuePlaybackMethod),callback:function ($$v) {_vm.valuePlaybackMethod=$$v},expression:"valuePlaybackMethod"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.PlayerSizes,"hint":"","label":_vm.$t('modifier.labels.video.player_size.label'),"placeholder":_vm.$t('modifier.labels.video.player_size.label'),"error_messages":_vm.getError('player_size'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchPlayerSizes()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelPlayerSize)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.video.player_size.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valuePlayerSize),callback:function ($$v) {_vm.valuePlayerSize=$$v},expression:"valuePlayerSize"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.StartDelays,"hint":"","label":_vm.$t('modifier.labels.video.start_delay.label'),"placeholder":_vm.$t('modifier.labels.video.start_delay.label'),"error_messages":_vm.getError('start_delay'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchStartDelays()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelStartDelay)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.video.start_delay.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueStartDelay),callback:function ($$v) {_vm.valueStartDelay=$$v},expression:"valueStartDelay"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.VideoPlacements,"hint":"","label":_vm.$t('modifier.labels.video.video_placement.label'),"placeholder":_vm.$t('modifier.labels.video.video_placement.label'),"error_messages":_vm.getError('video_placement'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchPlacements()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelVideoPlacement)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.video.video_placement.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueVideoPlacement),callback:function ($$v) {_vm.valueVideoPlacement=$$v},expression:"valueVideoPlacement"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }