
import Vue from "vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import CardAutocomplete from "./../../../../components/Content/CardAutocomplete.vue";
import { debounce, isNull, isUndefined, isEmpty } from "lodash";//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";
import { mapActions } from "vuex";

// Constants declaration to Kays value
const CITY = "City";
const COUNTRY = "Country";
const LAT_LONG = "Lat Long List";
const LOCATION_TYPE = "Location Type";
const REGION_STATE = "Region State";
const MIN_CONTENT_SEARCH = 3;

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";

export default Vue.extend({
	name: "GeoModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Geo",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		CardAutocomplete,
		ButtonTooltip
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		countryTerm: null,
		cityTerm: null,
		regionTerm: null,
		valueCity: null,
		valueCountry: null,
		valueLocationType: null,
		valueRegionState: null,
		valueLatLong: null,
		dataVariable:{
			dataCities:[],
			dataCountry:[],
			dataLatLong:[],
			dataRegion:[],
			dataLocation:[],
		},
	}),
	created() {},
	async mounted() {
	},
	computed: {
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},

		getErrors(){return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelCity(){return CITY;},

		getLabelCountry(){return COUNTRY;},

		getLabelLatLong(){return LAT_LONG;},

		getLabelLocationType(){return LOCATION_TYPE;},

		getLabelRegionState(){return REGION_STATE;},

		getName(){return this.module_name;},

		getMatchingTypes(){return this.matching_types;},

		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
	},
	methods: {

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", [	"getCities", 
											"getCountries", 
											"getLatLong",
											"getRegions",
											"getLocations"]),

		getError(index: string) {return getError(this.getErrors, index);},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case CITY:
					entity.key = "city";
					let cities = this.getAttributes(option, this.valueCity);
					entity.value = cities.value;
					entity.value_name = cities.value_name;
					entity.override_multiplier = cities.override_multiplier;
					this.valueCity = null;
					break;
				case COUNTRY:
					entity.key = "country";
					let country = this.getAttributes(option, this.valueCountry);
					entity.value = country.value;
					entity.value_name = country.value_name;
					entity.override_multiplier = country.override_multiplier;
					this.valueCountry = null;
					break;
				case REGION_STATE:
					entity.key = "region";
					let region = this.getAttributes(option, this.valueRegionState);
					entity.value = region.value;
					entity.value_name = region.value_name;
					entity.override_multiplier = region.override_multiplier;
					this.valueRegionState = null;
					break;
				case LAT_LONG:
					entity.key = "lat_long_list";
					let latLong = this.getAttributes(option, this.valueLatLong);
					entity.value = latLong.value;
					entity.value_name = latLong.value_name;
					entity.override_multiplier = latLong.override_multiplier;
					this.valueLatLong = null;
					break;
				case LOCATION_TYPE:
					entity.key = "location_type";
					let location = this.getAttributes(option, this.valueLocationType);
					entity.value = location.value;
					entity.value_name = location.value_name;
					entity.override_multiplier = location.override_multiplier;
					this.valueLocationType = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		async fetchCities(params: any){
			await this.setLoadingField(true);
			await this.getCities(params).then((response) => {
				this.dataVariable.dataCities = response;
				this.setLoadingField(false);
			}).catch((error) => {
				this.setLoadingField(false);
			});
		},

		async fetchCountry(params: any){
			await this.setLoadingField(true);
			await this.getCountries(params).then((response) => {
				this.dataVariable.dataCountry = response;
				this.setLoadingField(false);
			}).catch((error) => {
				this.setLoadingField(false);
			});
		},

		async fetchLatLong(params: any){
			await this.setLoadingField(true);
			await this.getLatLong(params).then((response) => {
				this.dataVariable.dataLatLong = response;
				this.setLoadingField(false);
			}).catch((error) => {
				this.setLoadingField(false);
			});
		},

		async fetchRegion(params: any){
			await this.setLoadingField(true);
			await this.getRegions(params).then((response) => {
				this.dataVariable.dataRegion = response;
				this.setLoadingField(false);
			}).catch((error) => {
				this.setLoadingField(false);
			});
		},

		dispatchChangeKey(key: String, term: String) {
			switch (key) {
				case CITY:
					this.cityTerm = term;
					break;
				case COUNTRY:
					this.countryTerm = term;
					break;
				case REGION_STATE:
					this.regionTerm = term;
					break;
			}
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		getAttributes(option: any, id?: Number){
			let dataAttribute = {value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case CITY:
					let cities = this.dataVariable.dataCities.find(e => e.id == id);
					dataAttribute.value = cities?.prefix;
					dataAttribute.value_name = cities?.name;
					dataAttribute.override_multiplier = this.isOverride("city");
					break;
				case COUNTRY:
					let country = this.dataVariable.dataCountry.find(e => e.id == id);
					dataAttribute.value = country?.prefix_3;
					dataAttribute.value_name = country?.name;
					dataAttribute.override_multiplier = this.isOverride("country");
					break;
				case REGION_STATE:
					let region = this.dataVariable.dataRegion.find(e => e.id == id);
					dataAttribute.value = region?.key;
					dataAttribute.value_name = region?.name;
					dataAttribute.override_multiplier = this.isOverride("region");
					break;
				case LAT_LONG:
					let latLong = this.dataVariable.dataLatLong.find(e => e.id == id);
					dataAttribute.value = latLong?.external_id;
					dataAttribute.value_name = latLong?.name;
					dataAttribute.override_multiplier = this.isOverride("lat_long_list");
					break;
				case LOCATION_TYPE:
					let location = this.dataVariable.dataLocation.find(e => e.id == id);
					dataAttribute.value = location?.extra;
					dataAttribute.value_name = location?.description;
					dataAttribute.override_multiplier = this.isOverride("city");
					break;
			}
			return dataAttribute;
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},
	watch: {
		cityTerm: debounce(async function (val: String, old: String) {
			if ( isNull(val) ) return;
			if ( val.length < MIN_CONTENT_SEARCH ) return;
			await this.fetchCities({term: val});
		}, 500),

		countryTerm: debounce(async function (val: String, old: String) {
			if ( isNull(val) ) return;
			if ( val.length < MIN_CONTENT_SEARCH ) return;
			await this.fetchCountry({term: val});
		}, 500),

		regionTerm: debounce(async function (val: String, old: String) {
			if ( isNull(val) ) return;
			if ( val.length < MIN_CONTENT_SEARCH ) return;
			await this.fetchRegion({term: val});
		}, 500),

	}
});
