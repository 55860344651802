import i18n from "@/plugins/i18n";
import { isUndefined, isEmpty } from "lodash";

const dictionary = {
    'app_bundle_list': 'appSite',
    'app_name': 'appSite',
    'content_rating': 'content',
    'language': 'content',
    'ad_size': 'environment',
    'ad_position': 'environment',
    'environment_type': 'environment',
    'interstitial': 'environment',
    'interstitial_type': 'environment',
    'topframe': 'environment',
    'video_api': 'environment',
    'auction_type': 'exchange',
    'inventory_source': 'exchange',
    'companion_required': 'video',
    'playback_method': 'video',
    'player_size': 'video',
    'start_delay': 'video',
    'video_placement_type': 'video'
}

export function prepareTableHeaders(){
    return [
        {
            text: "Module",
            align: "start",
            sortable: false,
            filterable: false,
            value: "module_name",
        },
        {
            text: "Key",
            align: "start",
            sortable: false,
            filterable: false,
            value: "key",
        },
        {
            text: "Type",
            align: "start",
            sortable: false,
            filterable: false,
            value: "matching_type_name",
        },
        {
            text: "Value",
            align: "center",
            sortable: false,
            filterable: false,
            value: "value_name",
        },
        {
            text: "Multiplier",
            align: "center",
            sortable: false,
            filterable: false,
            value: "modifier",
            width: "125px",
        },
        {
            text: "Dinamic",
            align: "center",
            sortable: false,
            filterable: false,
            value: "override_multiplier",
            width: "125px",
        },
        {
            text: "",
            align: "center",
            sortable: false,
            filterable: false,
            value: "actions",
        },
    ];
}

export function prepareTableHeadersDelivery(term: any[]){
    let arr = new Array();
    const firtObj = {
        text: i18n.t(`modifier.fields.priority`),
        align: "center",
        sortable: false,
        filterable: false,
        value: "rank",
        width: "50px",
    };
    arr.push(firtObj);
    if(!isEmpty(term)){
        term.forEach(element => {
            if(arr.findIndex((e) => e.value == element.key) != -1) return;
            arr.push(prepareItemHeader(element.key));
        });
    }
    const WeightCol = {
        text: "Weight",
        align: "center",
        sortable: false,
        filterable: false,
        width: "125px",
        value: "weight",
    };
    const porCol = {
        text: "%",
        align: "center",
        sortable: false,
        filterable: false,
        width: "125px",
        value: "porcentaje",
    };
    const deleteCol = {
        text: "",
        align: "center",
        sortable: false,
        filterable: false,
        width: "20px",
        value: "delete",
    };
    arr.push(WeightCol);
    arr.push(porCol);
    arr.push(deleteCol);
    return arr;
}

export function deleteTerm(term:any[]){
	let arr = new Array();
	term.forEach(element => {
        if(arr.findIndex((e) => e.text == element.module_name) != -1) return;
        arr.splice(element.key, element.module_name);
    });
	return arr;

}

export function prepareItemHeader(key: any){
    let prefix = dictionary[key];
    return {
        align:"start",
        filterable:false,
        sortable:false,
        text: i18n.t(`modifier.labels.${prefix}.${key}.label`),
        value: key
    }
}

export function prepareTooltipData(params: any) {
	return {
		icon: "mdi-information-outline",
		text: params,
		to: "",
		top: true,
		max_width: "500",
	};
}

export function prepareDataDelivery(data) {
    const {
        advertiser_id,
        name,
        fallback_weight,
        terms,
        alternative_id,
        id,
        modifier_type_id,
        active
    } = data;

    const newTerms: {}[] = []
    terms.forEach(term => {
        const newTerm = {
            weight: term.weight,
            rank: term.rank,
            targeting: term.targeting.map(target => {
                const newTarget = {
                    key: target.key,
                    value: target.value
                }

                return newTarget
            })
        }

        if(term.targeting.filter(target => target.value).length > 0) {
            newTerms.push(newTerm);
        }
    })
    
    return {
        advertiser_id,
        name,
        fallback_weight,
        terms: newTerms,
        alternative_id,
        id,
        modifier_type_id,
        active
    };
}

export function getModifiersOptions(){
    return [
        'app_bundle_list',
        'app_name',
        'ad_position',
        'content_rating',
        'language',
        'environment_type',
        'interstitial',
        'interstitial_type',
        'topframe',
        'video_api',
        'ad_size',
        'companion_required',
        'playback_method',
        'player_size',
        'start_delay',
        'video_placement_type',
        'auction_type',
        'inventory_source'
    ]
}