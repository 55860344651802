
import Vue from "vue";
import {
	isRequired,
	isNumber,
	isMax,
	isMin,
	// @ts-ignore
} from "@/services/rule-services";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
import { isUndefined } from "lodash";
// @ts-ignore
import { ModifiersOptions } from ".@/interfaces/modifier";

export default Vue.extend({
	name: "TableTerms",
	props: {
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default() {
				return [] as Array<ModifiersOptions>;
			},
		},
	},
	components: {
		CardTextField,
		IconBase,
		CardSwitch,
	},
	data: () => ({}),
	created() {},
	mounted() {},
	computed: {
		getRules() {
			return {
				isRequired,
				isNumber,
				isMax,
				isMin,
			};
		},
	},
	methods: {
		hasOverrideMultiplier(item: any) {
			return !isUndefined(item?.override_multiplier);
		},
		async handleDelete(entity: any) {
			this.$emit("delete-item", entity);
		},
	},
});
