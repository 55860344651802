
import Vue from "vue";
import { debounce, isNull, isObject, isUndefined } from "lodash";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import CardSearcherAttribute from "@/components/Content/CardSearcherAttribute.vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import { mapActions, mapGetters } from "vuex";
//@ts-ignore
import { getDataVariables } from "@/utils/initData";
//@ts-ignore
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore;
import InitModifierOption from "@/models/InitModifierOption";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";
const MIN_CONTENT_SEARCH = 3;
const DEBOUNCE_DELAY = 750;

export default Vue.extend({
	name: "AppSiteModule",

	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "App/Site",
		},
		matching_types: {
			type: Array,
			default() {
				return [];
			},
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		isDeliveryModifierType: {
			type: Boolean,
			default: false,
		},
	},

	components: {
		CardAutocomplete,
		CardSearcherAttribute,
		CardTextField,
		ButtonTooltip,
	},

	data: () => ({
		valid: false,
		entity: new InitModifierOption(),
		appNameTerm: null,
		siteTerm: null,
		resources: {
			app_bundle_list: [],
			app_name: [],
			deal_id_list: [],
			domain_list: [],
			placement_list: [],
			publisher_id: [],
			publisher_id_list: [],
			site: [],
			site_list: [],
		},
		field: {
			app_bundle_list: null,
			app_name: null,
			deal_id: "",
			deal_id_list: null,
			domain_list: null,
			placement_list: null,
			publisher_id: null,
			publisher_id_list: null,
			site: null,
			site_list: null,
		},
		by_attribute: {
			app_name: "app_name",
			site: "site_id",
		},
	}),

	created() {
		this.$nextTick(async () => {
			this.entity = new InitModifierOption();
		});
	},

	async mounted() {
		this.$nextTick(async () => {});
	},

	computed: {
		...mapGetters("loading", ["isLoading"]),

		isDeliveryType(){
			return this.isDeliveryModifierType;
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},
		getMatchingType() {
			return this.matching_types.find(
				(e: { value: string }) => e.value == MATCHING_TYPE_DEFAULT
			);
		},
		getMatchingTypeId(): Number {
			return this.matching_types.find(
				(e: { value: string }) => e.value == MATCHING_TYPE_DEFAULT
			)?.id;
		},
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("proccess", ["setLoadingMessage"]),
		...mapActions("targeting", ["getAppNameByAtribute"]),
		...mapActions("targeting", ["getSitesByAtribute"]),
		...mapActions("targeting", ["getTargetingKey"]),

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		getFilterOptions(term: string) {
			return getDataVariables()[term];
		},

		syncData(key: string, term: string | null = null) {
			switch (key) {
				case "app_name":
					this.appNameTerm = term;
					break;

				case "site":
					this.siteTerm = term;
					break;

				case "placement_id":
					this.placementIdTerm = term;
					break;
			}
		},

		async getFieldByKey(key: string) {
			let params = {};
			switch (key) {
				case "app_name":
					params = {
						key: "app_name",
						value: "app_name",
					};
					break;

				case "site":
					params = {
						key: "site_id",
						value: "site_id",
					};
					break;

				default:
					params = {
						key: "external_id",
						value: "name",
					};
					break;
			}
			return params;
		},

		async getComboData(key: string) {
			try {
				this.resources[key] = await this.getTargetingKey({
					key,
					object: await this.getFieldByKey(key),
				});
			} catch (error) {
				console.error("getComboData", { key, error });
			}
		},

		/**
		 * Prepare term
		 */
		async getTermModifier(key: string, item: { id: any; value: any }) {
			const matchingType = this.getMatchingType;
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);

			this.entity = new InitModifierOption(
				key,
				String(item.id),
				item.value,
				this.module_id,
				this.module_name,
				matchingType?.id,
				matchingType?.value,
				hasOverrideMultiplier
					? modifierOption.override_multiplier == "true"
						? 1
						: 0
					: undefined
			);

			return this.entity;
		},

		/**
		 * Handler
		 * Al detectar cambios en los campos agrega un nuevo 'term'
		 */
		async handleChange(key: string, item: any) {
			//console.log("handleChange", { key, item });
			const _ID: any | null = isObject(item) ? item["id"] : item;
			const _VALUE: string | null = isObject(item) ? item["value"] : item;
			const _LENGTH: number =
				!isNull(_VALUE) && !isUndefined(_VALUE) ? _VALUE.length : 0;

			let params = { id: _ID, value: _VALUE };

			if (!_VALUE || _LENGTH < MIN_CONTENT_SEARCH) return;

			this.$emit("add-term", await this.getTermModifier(key, params));

			this.field[key] = null;
			this.syncData(key);
		},

		/**
		 * Handler
		 * Captura el evento del Enter
		 * Concatena el valor con un (,) cada vez que presiona Enter
		 */
		addCommaHandler(key: string, value: any) {
			console.log("addCommaHandler", {
				key,
				value,
				field: this.field[key],
			});
			this.field[key] = this.field[key]?.concat(",");
		},

		/**
		 * Busquedas con parametro: 'by_attribute'
		 */
		async fetchByAtribute(params: {
			key: string;
			term: string;
			by_attribute: string;
		}) {
			switch (params.key) {
				case "app_name":
					return await this.getAppNameByAtribute(params);
				case "site":
					return await this.getSitesByAtribute(params);
			}
		},

		async fetchingByAttribute(
			key: any,
			value: any,
			object: { key: any; value: any }
		) {
			await this.setLoadingData(TypeLoading.loading);
			const result: Array<any> = await this.fetchByAtribute({
				key: key,
				term: value,
				by_attribute: this.by_attribute[key],
				object: object,
			});
			this.resources[key] = result;
			await this.setLoadingData();
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},

	watch: {
		appNameTerm: debounce(async function (val: string) {
			if (isNull(val) || (val && val.length < MIN_CONTENT_SEARCH)) return;
			await this.fetchingByAttribute(
				"app_name",
				val,
				await this.getFieldByKey("app_name")
			);
		}, DEBOUNCE_DELAY),
		siteTerm: debounce(async function (val: string) {
			if (isNull(val) || (val && val.length < MIN_CONTENT_SEARCH)) return;
			await this.fetchingByAttribute(
				"site",
				val,
				await this.getFieldByKey("site")
			);
		}, DEBOUNCE_DELAY),
	},
});
