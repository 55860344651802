var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"formPlatform",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataBandwidth,"hint":"","label":_vm.$t('modifier.labels.platform.bandwidth.label'),"placeholder":_vm.$t('modifier.labels.platform.bandwidth.label'),"error_messages":_vm.getError('bandwidth'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchBandwidth()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelBandwidth)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.bandwidth.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueBandwidth),callback:function ($$v) {_vm.valueBandwidth=$$v},expression:"valueBandwidth"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataBrowsers,"hint":"","label":_vm.$t('modifier.labels.platform.browser.label'),"placeholder":_vm.$t('modifier.labels.platform.browser.label'),"error_messages":_vm.getError('browser'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchBrowsers()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelBrowser)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.browser.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueBrowser),callback:function ($$v) {_vm.valueBrowser=$$v},expression:"valueBrowser"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataBrowserVersion,"hint":"","label":_vm.$t('modifier.labels.platform.browser_version.label'),"placeholder":_vm.$t('modifier.labels.platform.browser_version.label'),"error_messages":_vm.getError('browser_version'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchBrowserVersions()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelBrowserVersion)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.browser_version.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueBrowserVersion),callback:function ($$v) {_vm.valueBrowserVersion=$$v},expression:"valueBrowserVersion"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataCarriers,"hint":"","label":_vm.$t('modifier.labels.platform.carrier.label'),"placeholder":_vm.$t('modifier.labels.platform.carrier.label'),"error_messages":_vm.getError('carrier'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchCarriers()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelCarrier)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.carrier.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueCarrier),callback:function ($$v) {_vm.valueCarrier=$$v},expression:"valueCarrier"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataDeviceMakes,"hint":"","label":_vm.$t('modifier.labels.platform.device_make.label'),"placeholder":_vm.$t('modifier.labels.platform.device_make.label'),"error_messages":_vm.getError('device_make'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchDeviceMakes()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelDeviceMake)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.device_make.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueDeviceMake),callback:function ($$v) {_vm.valueDeviceMake=$$v},expression:"valueDeviceMake"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataDeviceModels,"hint":"","label":_vm.$t('modifier.labels.platform.device_model.label'),"placeholder":_vm.$t('modifier.labels.platform.device_model.label'),"error_messages":_vm.getError('device_model'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchDeviceModels()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelDeviceModel)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.device_model.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueDeviceModel),callback:function ($$v) {_vm.valueDeviceModel=$$v},expression:"valueDeviceModel"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataDeviceScreen,"hint":"","label":_vm.$t('modifier.labels.platform.device_screen_size.label'),"placeholder":_vm.$t('modifier.labels.platform.device_screen_size.label'),"error_messages":_vm.getError('device_screen_size'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchDeviceScreenSizes()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelDeviceScreenSize)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.device_screen_size.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueDeviceScreenSize),callback:function ($$v) {_vm.valueDeviceScreenSize=$$v},expression:"valueDeviceScreenSize"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataDeviceTypes,"hint":"","label":_vm.$t('modifier.labels.platform.device_type.label'),"placeholder":_vm.$t('modifier.labels.platform.device_type.label'),"error_messages":_vm.getError('device_type'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchDeviceTypes()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelDeviceType)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.device_type.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueDeviceType),callback:function ($$v) {_vm.valueDeviceType=$$v},expression:"valueDeviceType"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8","lg":"8"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataOperationSystem,"hint":"","label":_vm.$t('modifier.labels.platform.operating_system.label'),"placeholder":_vm.$t('modifier.labels.platform.operating_system.label'),"error_messages":_vm.getError('operating_system'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchOperatingSystems()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelOperatingSystem)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.operating_system.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueOperatingSystem),callback:function ($$v) {_vm.valueOperatingSystem=$$v},expression:"valueOperatingSystem"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataOperationSystemVersion,"hint":"","label":_vm.$t('modifier.labels.platform.operating_system_version.label'),"placeholder":_vm.$t('modifier.labels.platform.operating_system_version.label'),"error_messages":_vm.getError('operating_system_version'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchOperatingSystemVersions()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelOperatingSystemVersion)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.platform.operating_system_version.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueOperatingSystemVer),callback:function ($$v) {_vm.valueOperatingSystemVer=$$v},expression:"valueOperatingSystemVer"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }