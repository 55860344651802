
import Vue from "vue";

export default Vue.extend({
    name:"HeaderTableDelivery",
    props:{
        column: {
            type: Object,
            default: function() {
                return {}
            }
        }
    },
    components: {},
    data: () => ({}),
    created() {},
    mounted() {},
    computed:{
        getText(){
            return this.column.text;
        }
    },
    methods:{
        async handleRemove(){
            this.$emit("handle-remove", this.column);
        }
    }
});
