
import Vue from "vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import CardAutocomplete from "./../../../../components/Content/CardAutocomplete.vue";
import CardTextField from "./../../../../components/Content/CardTextField.vue";
import { isNull, isUndefined, isEmpty } from "lodash";
import { mapActions } from "vuex";
//@ts-ignore
import { TypeLoading } from "@/interfaces/loading";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import RadioButton from "@/components/Content/RadioButton.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";

	// Constants declaration to Kays value
	const AD_POSITION = "Ad Position";
	const SEARCH_SIZE = "Size";
	const ENVIRONMENT_TYPE = "Environment Type";
	const INTERSTICIAL = "Intersticial";
	const INTERSTICIAL_TYPE = "Intersticial Type";
	const API_FRAMEWORK = "API Framework";
	const TOP_FRAME = "Top Frame";

	// Default Matching Type
	const MATCHING_TYPE_DEFAULT = "Equals";

export default Vue.extend({
	name: "InventoryModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Environment",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		isDeliveryModifierType: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		CardAutocomplete,
		CardTextField,
		ButtonTooltip,
		RadioButton
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueAdPosition: null,
		valueAddSize: null,
		valueEnvironmentType: null,
		valueIntersticial: null,
		valueIntersticialType: null,
		valueAPIFramework: null,
		valueTopFrame: null,
		dataVariable:{
			dataAdPosition:[],
			dataAdSize:[],
			dataEnvironmentTypes:[],
			dataIntersticial:[],
			dataIntersticialType:[],
			dataAPIFramework:[],
			dataTopFrame:[],
		},
	}),
	created() {},
	async mounted() {
		this.$nextTick(async () => {
			await this.onLoadResources();
		});
	},
	computed: {

		isDeliveryType(){
			return this.isDeliveryModifierType;
		},
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},

		getErrors() {return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getName(){return this.module_name;},

		getLabelAdPosition(){return AD_POSITION;},

		getLabelSearchSize(){return SEARCH_SIZE;},

		getLabelEnvironmentType(){return ENVIRONMENT_TYPE;},

		getLabelIntersticialFlag(){return INTERSTICIAL;},

		getLabelIntersticialType(){return INTERSTICIAL_TYPE;},

		getLabelAPIFramework(){return API_FRAMEWORK;},

		getLabelTopFrame(){return TOP_FRAME;},
		
		getMatchingTypes(){return this.matching_types;},
		
		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
		getRadioButtonItems() {
			return this.dataVariable.dataIntersticial.map((d: any) => {
				return {
					id: d.id,
					value: d.description
				}
			});
		}
	},
	methods: {

		...mapActions("loading", ["setLoadingData"]),

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", [	"getPositions", 
											"getSizes",
											"getEnvironmentTypes",
											"getInterstitialFlags",
											"getInterstitialTypes",
											"getAPIFrameworks",
											"getTopFrame"
											]),

		async onLoadResources(){
			await this.setLoadingData(TypeLoading.loading);
			await this.fetchInterstitialFlags();
			await this.getTermTime();
			await this.setLoadingData();
		},

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case AD_POSITION:
					entity.key = "ad_position";
					let position = this.getAttributes(option, this.valueAdPosition);
					entity.value = position.value;
					entity.value_name = position.value_name;
					entity.override_multiplier = position.override_multiplier;
					this.valueAdPosition = null;
					break;
				case SEARCH_SIZE:
					entity.key = "ad_size";
					let size = this.getAttributes(option, this.valueAddSize);
					entity.value = size.value;
					entity.value_name = size.value_name;
					entity.override_multiplier = size.override_multiplier;
					this.valueAddSize = null;
					break;
				case ENVIRONMENT_TYPE:
					entity.key = "environment_type";
					let environment = this.getAttributes(option, this.valueEnvironmentType);
					entity.value = environment.value;
					entity.value_name = environment.value_name;
					entity.override_multiplier = environment.override_multiplier;
					this.valueEnvironmentType = null;
					break;
				case INTERSTICIAL:
					entity.key = "interstitial";
					let interticial = this.getAttributes(option, this.valueIntersticial);
					entity.value = interticial.value;
					entity.value_name = interticial.value_name;
					entity.override_multiplier = interticial.override_multiplier;
					this.valueIntersticial = null;
					break;
				case INTERSTICIAL_TYPE:
					entity.key = "interstitial_type";
					let interticialType = this.getAttributes(option, this.valueIntersticialType);
					entity.value = interticialType.value;
					entity.value_name = interticialType.value_name;
					entity.override_multiplier = interticialType.override_multiplier;
					this.valueIntersticialType = null;
					break;
				case API_FRAMEWORK:
					entity.key = "video_api";
					let framework = this.getAttributes(option, this.valueAPIFramework);
					entity.value = framework.value;
					entity.value_name = framework.value_name;
					entity.override_multiplier = framework.override_multiplier;
					this.valueAPIFramework = null;
					break;
				case TOP_FRAME:
					entity.key = "topframe";
					let frame = this.getAttributes(option, this.valueTopFrame);
					entity.value = frame.value;
					entity.value_name = frame.value_name;
					entity.override_multiplier = frame.override_multiplier;
					this.valueTopFrame = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		async fetchAdPosition(){
			if(isEmpty(this.dataVariable.dataAdPosition)){
				await this.setLoadingField(true);
				await this.getPositions().then((response) => {
					this.dataVariable.dataAdPosition = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchAdSize(){
			if(isEmpty(this.dataVariable.dataAdSize)){
				await this.setLoadingField(true);
				await this.getSizes().then((response) => {
					this.dataVariable.dataAdSize = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchEnvironmentTypes(){
			if(isEmpty(this.dataVariable.dataEnvironmentTypes)){
				await this.setLoadingField(true);
				await this.getEnvironmentTypes().then((response) => {
					this.dataVariable.dataEnvironmentTypes = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchInterstitialFlags(){
			if(isEmpty(this.dataVariable.dataIntersticial)){
				await this.setLoadingField(true);
				await this.getInterstitialFlags().then((response) => {
					this.dataVariable.dataIntersticial = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchInterstitialTypes(){
			if(isEmpty(this.dataVariable.dataIntersticialType)){
				await this.setLoadingField(true);
				await this.getInterstitialTypes().then((response) => {
					this.dataVariable.dataIntersticialType = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchAPIFrameworks(){
			if(isEmpty(this.dataVariable.dataAPIFramework)){
				await this.setLoadingField(true);
				await this.getAPIFrameworks().then((response) => {
					this.dataVariable.dataAPIFramework = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchTopFrame(){
			if(isEmpty(this.dataVariable.dataTopFrame)){
				await this.setLoadingField(true);
				await this.getTopFrame().then((response) => {
					this.dataVariable.dataTopFrame = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async getTermTime(){
			this.$emit("get-term", "interstitial");
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		getAttributes(option: any){
			let dataAttribute = { value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case AD_POSITION:
					let position = this.dataVariable.dataAdPosition.find(e => e.id == this.valueAdPosition);
					dataAttribute.value = position?.external_id;
					dataAttribute.value_name = position?.description;
					dataAttribute.override_multiplier = this.isOverride("ad_position");
					break;
				case SEARCH_SIZE:
					let size = this.dataVariable.dataAdSize.find(e => e.id == this.valueAddSize);
					dataAttribute.value = size?.name;
					dataAttribute.value_name = size?.name;
					dataAttribute.override_multiplier = this.isOverride("ad_size");
					break;
				case ENVIRONMENT_TYPE:
					let environment = this.dataVariable.dataEnvironmentTypes.find(e => e.id == this.valueEnvironmentType);
					dataAttribute.value = environment?.extra;
					dataAttribute.value_name = environment?.description;
					dataAttribute.override_multiplier = this.isOverride("environment_type");
					break;
				case INTERSTICIAL:
					let intersticial = this.dataVariable.dataIntersticial.find(e => e.id == this.valueIntersticial);
					dataAttribute.value = intersticial?.extra;
					dataAttribute.value_name = intersticial?.description;
					dataAttribute.override_multiplier = this.isOverride("interstitial");
					break;
				case INTERSTICIAL_TYPE:
					let intersticialType = this.dataVariable.dataIntersticialType.find(e => e.id == this.valueIntersticialType);
					dataAttribute.value = intersticialType?.value.toUpperCase();
					dataAttribute.value_name = intersticialType?.value;
					dataAttribute.override_multiplier = this.isOverride("interstitial_type");
					break;
				case API_FRAMEWORK:
					let framework = this.dataVariable.dataAPIFramework.find(e => e.id == this.valueAPIFramework);
					dataAttribute.value = framework?.extra;
					dataAttribute.value_name = framework?.description;
					dataAttribute.override_multiplier = this.isOverride("video_api");
					break;
				case TOP_FRAME:
					let frame = this.dataVariable.dataTopFrame.find(e => e.id == this.valueTopFrame);
					dataAttribute.value = frame?.extra;
					dataAttribute.value_name = frame?.description;
					dataAttribute.override_multiplier = this.isOverride("topframe");
					break;
			}
			return dataAttribute;
		},

		setTerm(valuesTerm: any){
			if(!isEmpty(valuesTerm)){
				this.valueIntersticial = this.dataVariable.dataIntersticial.find(i => i.extra == valuesTerm[0].value)?.id;
			}
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},

	},
	watch: {}
});
