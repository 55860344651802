var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"formContent",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[(!this.isDeliveryType)?_c('card-autocomplete',{attrs:{"items":_vm.getCategories,"hint":"","label":_vm.$t('modifier.labels.content.content_category.label'),"placeholder":_vm.$t('modifier.labels.content.content_category.label'),"error_messages":_vm.getError('content_category'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"change":function($event){return _vm.handleChangeSelection(_vm.getLabelContentCategory)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.content.content_category.help'
									)
								)}})]},proxy:true}],null,false,1300919612),model:{value:(_vm.valueContentCategory),callback:function ($$v) {_vm.valueContentCategory=$$v},expression:"valueContentCategory"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[(this.isDeliveryType)?_c('card-autocomplete',{attrs:{"items":_vm.getRatings,"hint":"","label":_vm.$t('modifier.labels.content.content_rating.label'),"placeholder":_vm.$t('modifier.labels.content.content_rating.label'),"error_messages":_vm.getError('content_rating'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"change":function($event){return _vm.handleChangeSelection(_vm.getLabelContentRating)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.content.content_rating.help'
									)
								)}})]},proxy:true}],null,false,1300905643),model:{value:(_vm.valueContentRating),callback:function ($$v) {_vm.valueContentRating=$$v},expression:"valueContentRating"}}):_vm._e()],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.getLanguages,"hint":"","label":_vm.$t('modifier.labels.content.language.label'),"placeholder":_vm.$t('modifier.labels.content.language.label'),"error_messages":_vm.getError('language'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"change":function($event){return _vm.handleChangeSelection(_vm.getLabelLanguage)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
									_vm.$t(
										'modifier.labels.content.language.help'
									)
								)}})]},proxy:true}]),model:{value:(_vm.valueLanguage),callback:function ($$v) {_vm.valueLanguage=$$v},expression:"valueLanguage"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }