
import Vue from "vue";
import CardTextField from "../../../../components/Content/CardTextField.vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import { isNull, isUndefined, isEmpty } from "lodash";
import { mapActions } from "vuex";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";

const SEGMENTS = "Segments";
const IP_ADDRESS_START = "IP Address Start";
const IP_ADDRESS_END = "IP Address End";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";
const MATCHING_TYPE_IN_RANGE = "In range"

export default Vue.extend({
	name: "UserModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "User",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		CardTextField,
		CardAutocomplete,
		ButtonTooltip
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueSegmentsList: null,
		valueIPAddressStart: "",
		valueIPAddressEnd: "",
		dataVariable:{
			dataSegments:[],
		},
		filterSegments:{
			filters: {},
			options: { sort: "id", order: "asc" }
		},
		ip_start_range:[],
		ip_end_range:[],
	}),
	created() {},
	async mounted() {},
	computed: {
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},

		getErrors() {return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelSegments(){return SEGMENTS;},

		getLabelIPAddressStart(){return IP_ADDRESS_START;},

		getLabelIPAddressEnd(){return IP_ADDRESS_END;},

		getName(){return this.module_name;},

		getMatchingTypes(){return this.matching_types;},
		
		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
	},
	methods: {

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", ["getSegmentsList"]),

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case SEGMENTS:
					this.setTermSegment(entity, option);
					break;
			}
		},

		clearRules(){
			this.ip_start_range = [];
			this.ip_end_range = [];
		},

		async addRules(){
			this.ip_start_range = [this.getRules.isRequired, this.validateIp(this.valueIPAddressStart)];
			this.ip_end_range = [this.getRules.isRequired, this.validateIp(this.valueIPAddressEnd)];
		},

		async fetchSegments(params: any){
			if(isEmpty(this.dataVariable.dataSegments)){
				await this.setLoadingField(true);
				await this.getSegmentsList(this.filterSegments).then((response) => {
					this.dataVariable.dataSegments = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async resetValidation() {
			let form = this.$refs.form;
			form.resetValidation();
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async setIPRange(){
			//if(isEmpty(this.valueIPAddressStart) || isNull(this.valueIPAddressStart)) return;
			//if(isEmpty(this.valueIPAddressEnd) || isNull(this.valueIPAddressEnd)) return;
			
			await this.addRules();

			if (!(await this.validate())) return;

			let entity: any = initModifierTerm();
			let matchingType = this.getMatchingTypeByType(MATCHING_TYPE_IN_RANGE);
			let ips = this.getAttributes(IP_ADDRESS_START);
			let ipe = this.getAttributes(IP_ADDRESS_END);

			entity.key = "ip_address";
			entity.value = ips.value + "," + ipe.value;
			entity.value_name = ips.value + "," + ipe.value;
			entity.override_multiplier = this.isOverride("ip_address");

			this.valueIPAddressStart = "";
			this.valueIPAddressEnd = "";

			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = matchingType?.id;
			entity.matching_type_name = matchingType?.value;
			this.$emit("add-term", entity);
			this.clearRules();
		},

		setTermSegment(entity: any, option: any){
			entity.key = "segment";
			let s = this.getAttributes(option, this.valueSegmentsList);
			entity.value = s.value;
			entity.value_name = s.value_name;
			entity.override_multiplier = s.override_multiplier;
			this.valueSegmentsList = null;
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		getAttributes(option: any, id?: Number){
			let dataAttribute = {value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case SEGMENTS:
					let segments = this.dataVariable.dataSegments.find(e => e.id == id);
					dataAttribute.value = segments?.key;
					dataAttribute.value_name = segments?.value;
					dataAttribute.override_multiplier = this.isOverride("segment");
					break;
				case IP_ADDRESS_START:
					dataAttribute.value = this.valueIPAddressStart;
					dataAttribute.value_name = this.valueIPAddressStart;
					dataAttribute.override_multiplier = this.isOverride("ip_address");
					break;
				case IP_ADDRESS_END:
					dataAttribute.value = this.valueIPAddressEnd;
					dataAttribute.value_name = this.valueIPAddressEnd;
					dataAttribute.override_multiplier = this.isOverride("ip_address");
					break;
			}
			return dataAttribute;
		},

		validateIp(ip: String) {
			var patronIp = new RegExp("^([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3}).([0-9]{1,3})$");
			var valores;

			if(ip.search(patronIp) !== 0) {
				return false
			}

			valores = ip.split("."); 

			return valores[0] <= 245 && valores[1] <= 255 && valores[2] <= 255 && valores[3] <= 255
		},

		getMatchingTypeByType(type: any){
			return this.matching_types.find((m) => m.value == type);
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},
	watch: {}
});
