
import Vue from "vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import { isNull, isUndefined, isEmpty } from "lodash";
import { mapActions } from "vuex";
//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";

// Constants declaration to Kays value
const COMPANION_SUPPORT = "Companion Support";
const PLAYBACK_METHOD = "Playback Method";
const PLAYER_SIZE = "Player Size";
const START_DELAY = "Start Delay";
const VIDEO_PLACEMENT = "Video Placement";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";

export default Vue.extend({
	name: "VideoModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Video",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		CardAutocomplete,
		ButtonTooltip
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueCompanionSupport: null,
		valuePlaybackMethod: null,
		valuePlayerSize: null,
		valueStartDelay: null,
		valueVideoPlacement: null,
		dataVariable:{
			CompanionSupports:[],
			VideoPlaybackMethods:[],
			PlayerSizes:[],
			StartDelays:[],
			VideoPlacements:[]
		}
	}),
	created() {},
	async mounted() {},
	computed: {
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},
		
		getErrors() {return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelCompanionSupport(){return COMPANION_SUPPORT;},

		getLabelPlaybackMethod(){return PLAYBACK_METHOD;},

		getLabelPlayerSize(){return PLAYER_SIZE;},

		getLabelStartDelay(){return START_DELAY;},

		getLabelVideoPlacement(){return VIDEO_PLACEMENT;},

		getName(){return this.module_name;},

		getMatchingTypes(){return this.matching_types;},
		
		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
	},
	methods: {

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", ["getCompanionSupports",
											"getVideoPlaybackMethods",
											"getPlayerSizes",
											"getStartDelays",
											"getVideoPlacements"]),

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case COMPANION_SUPPORT:
					entity.key = "companion_required";
					let cs = this.getAttributes(option, this.valueCompanionSupport);
					entity.value = cs.value;
					entity.value_name = cs.value_name;
					entity.override_multiplier = cs.override_multiplier;
					this.valueCompanionSupport = null;
					break;
				case PLAYBACK_METHOD:
					entity.key = "playback_method";
					let pm = this.getAttributes(option, this.valuePlaybackMethod);
					entity.value = pm.value;
					entity.value_name = pm.value_name;
					entity.override_multiplier = pm.override_multiplier;
					this.valuePlaybackMethod = null;
					break;
				case PLAYER_SIZE:
					entity.key = "player_size";
					let ps = this.getAttributes(option, this.valuePlayerSize);
					entity.value = ps.value;
					entity.value_name = ps.value_name;
					entity.override_multiplier = ps.override_multiplier;
					this.valuePlayerSize = null;
					break;
				case START_DELAY:
					entity.key = "start_delay";
					let sd = this.getAttributes(option, this.valueStartDelay);
					entity.value = sd.value;
					entity.value_name = sd.value_name;
					entity.override_multiplier = sd.override_multiplier;
					this.valueStartDelay = null;
					break;
				case VIDEO_PLACEMENT:
					entity.key = "video_placement_type";
					let vp = this.getAttributes(option, this.valueVideoPlacement);
					entity.value = vp.value;
					entity.value_name = vp.value_name;
					entity.override_multiplier = vp.override_multiplier;
					this.valueVideoPlacement = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		async fetchSupports(){
			if(isEmpty(this.dataVariable.CompanionSupports)){
				await this.setLoadingField(true);
				await this.getCompanionSupports().then((response) => {
					this.dataVariable.CompanionSupports = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchPlaybackMethods(){
			if(isEmpty(this.dataVariable.VideoPlaybackMethods)){
				await this.setLoadingField(true);
				await this.getVideoPlaybackMethods().then((response) => {
					this.dataVariable.VideoPlaybackMethods = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchPlayerSizes(){
			if(isEmpty(this.dataVariable.PlayerSizes)){
				await this.setLoadingField(true);
				await this.getPlayerSizes().then((response) => {
					this.dataVariable.PlayerSizes = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchStartDelays(){
			if(isEmpty(this.dataVariable.StartDelays)){
				await this.setLoadingField(true);
				await this.getStartDelays().then((response) => {
					this.dataVariable.StartDelays = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchPlacements(){
			if(isEmpty(this.dataVariable.VideoPlacements)){
				await this.setLoadingField(true);
				await this.getVideoPlacements().then((response) => {
					this.dataVariable.VideoPlacements = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		getAttributes(option: any, id: Number){
			let dataAttribute = {value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case COMPANION_SUPPORT:
					let companionS = this.dataVariable.CompanionSupports.find(e => e.id == id);
					dataAttribute.value = companionS?.extra;
					dataAttribute.value_name = companionS?.description;
					dataAttribute.override_multiplier = this.isOverride("companion_required");
					break;
				case PLAYBACK_METHOD:
					let playMethod = this.dataVariable.VideoPlaybackMethods.find(e => e.id == id);
					dataAttribute.value = playMethod?.extra;
					dataAttribute.value_name = playMethod?.description;
					dataAttribute.override_multiplier = this.isOverride("playback_method");
					break;
				case PLAYER_SIZE:
					let playSize = this.dataVariable.PlayerSizes.find(e => e.id == id);
					dataAttribute.value = playSize?.extra;
					dataAttribute.value_name = playSize?.description;
					dataAttribute.override_multiplier = this.isOverride("player_size");
					break;
				case START_DELAY:
					let startDelay = this.dataVariable.StartDelays.find(e => e.id == id);
					dataAttribute.value = startDelay?.extra;
					dataAttribute.value_name = startDelay?.description;
					dataAttribute.override_multiplier = this.isOverride("start_delay");
					break;
				case VIDEO_PLACEMENT:
					let videoPlacement = this.dataVariable.VideoPlacements.find(e => e.id == id);
					dataAttribute.value = videoPlacement?.extra;
					dataAttribute.value_name = videoPlacement?.description;
					dataAttribute.override_multiplier = this.isOverride("video_placement_type");
					break;
			}
			return dataAttribute;
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},
	watch: {}
});
