
export default {
	name: "CardSearcherAttribute",
	model: {
		prop: "model_data",
		event: "input",
	},
	components: {},
	props: {
		model_data: {},
		rules: {
			type: Array,
			default: function () {
				return [];
			},
		},
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		hint: {
			type: String,
			default: "",
		},
		reference: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		customClass: {
			type: String,
			default: "label-fixed static",
		},
		counter: {
			type: [Number, Boolean],
			default: 255,
		},
		item_text: {
			type: String,
			default: "value",
		},
		item_value: {
			type: String,
			default: "id",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		chips: {
			type: Boolean,
			default: false,
		},
		deletable_chips: {
			type: Boolean,
			default: false,
		},
		small_chips: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
			default: false,
		},
		persistent_hint: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		auto_select_first: {
			type: Boolean,
			default: false,
		},
		search_input_sync: {
			default: null,
		},
		colapse_selection: {
			type: Boolean,
			default: false,
		},
		show_id: {
			type: Boolean,
			default: false,
		},
		hide_no_data: {
			type: Boolean,
			default: true,
		},
		append_outer_data: {
			type: Object,
			default: function () {
				return {};
			},
		},
		append_icon: {
			type: String,
			default: "",
		},
		prepend_icon: {
			type: String,
			default: "",
		},
		prepend_inner_icon: {
			type: String,
			default: "",
		},
		item_select_all: {
			type: Boolean,
			default: false,
		},
		error_messages: {
			type: [Array, String],
			default: function () {
				return [];
			},
		},
		return_object: {
			type: Boolean,
			default: false,
		},
		selected_all: {
			type: Array,
			default: function () {
				return [];
			},
		},
		cardStyles: {
			type: String,
			default: "px-2 mb-2",
		},
		single_line: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({}),

	mounted() {},
	computed: {
		isLoading() {
			return this.$store.state.proccess.loading_field;
		},
		searchInputSync: {
			set(val: any) {
				this.$emit("sync", val);
			},
			get() {
				return this.search_input_sync;
			},
		},
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
		getCustomClass() {
			return `${this.customClass} ${
				this.disabled || this.readonly ? "disabled" : ""
			}`;
		},
		isClearable() {
			if (this.clearable) return true;
			return !this.disabled && !this.readonly;
		},
	},
	methods: {
		clickEvent(e: any) {
			this.$emit("click", e);
		},
		changeEvent(e: any) {
			this.$emit("change", e);
		},
		focusEvent(e: any) {
			this.$emit("focus", e);
		},
	},
	watch: {},
};
