import { ModifiersOptions } from '@/interfaces/modifier'

export default class InitModifierOption implements ModifiersOptions {
  key: any = undefined
  value: any = undefined
  value_name: string = ''
  module_id: number | undefined = undefined
  module_name: string = ''
  matching_type_id: number | undefined = undefined
  matching_type_name: string = ''
  override_multiplier?: number | undefined

  constructor(
    key?: any,
    value?: any,
    value_name?: string,
    module_id?: number | undefined,
    module_name?: string,
    matching_type_id?: number | undefined,
    matching_type_name?: string,
    override_multiplier?: number | undefined,
  ) {
    this.key = key
    this.value = value
    this.value_name = value_name || ""
    this.module_id = module_id
    this.module_name = module_name || ""
    this.matching_type_id = matching_type_id
    this.matching_type_name = matching_type_name || ""
    this.override_multiplier = override_multiplier
  }
}
