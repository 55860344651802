
import Vue from "vue";
import CardTextField from "../../../../components/Content/CardTextField.vue";
import CardAutocomplete from "../../../../components/Content/CardAutocomplete.vue";
import { getError } from "../../../../utils/resolveObjectArray";
import { isRequired } from "../../../../services/rule-services";
//@ts-ignore
import { initModifierTerm } from "@/utils/initData";
import { isNull, isUndefined, isEmpty } from "lodash";
import { mapActions } from "vuex";//@ts-ignore
import ButtonTooltip from "@/components/Content/Commons/ButtonTooltip.vue";
//@ts-ignore
import { prepareTooltipData } from "@/views/Admin/Persons/V4/Dependencies/FormFilters/utils";

// Constants declaration to Kays value
const BANDWIDTH = "Bandwidth";
const BROWSER = "Browser";
const BROWSER_VERSION = "Browser Version";
const CARRIER = "Carrier";
const DEVICE_MAKE = "Device Make";
const DEVICE_MODEL = "Device Model";
const DEVICE_SCREEN_SIZE = "Device Screen Size";
const DEVICE_TYPE = "Device Type";
const OPERATING_SYSTEM = "Operating System";
const OPERATING_SYSTEM_VER = "Operating System Version";

// Default Matching Type
const MATCHING_TYPE_DEFAULT = "Equals";

export default Vue.extend({
	name: "PlatformModule",
	props: {
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		module_name: {
			type: String,
			default: "Platform",
		},
		matching_types: {
			type: Array,
			default() {
				return []
			}
		},
		modifiers_options: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		CardTextField,
		CardAutocomplete,
		ButtonTooltip
	},
	data: () => ({
		valid: false,
		entity: initModifierTerm(),
		valueBandwidth: null,
		valueBrowser: null,
		valueBrowserVersion: null,
		valueCarrier: null,
		valueDeviceMake: null,
		valueDeviceModel: null,
		valueDeviceScreenSize: null,
		valueDeviceType: null,
		valueOperatingSystem: null,
		valueOperatingSystemVer: null,
		dataVariable:{
			dataBandwidth:[],
			dataBrowsers:[],
			dataBrowserVersion:[],
			dataCarriers:[],
			dataDeviceMakes:[],
			dataDeviceModels:[],
			dataDeviceScreen:[],
			dataDeviceTypes:[],
			dataOperationSystem:[],
			dataOperationSystemVersion:[]
		}
	}),
	created() {},
	async mounted() {},
	computed: {
		/**
		 * GET
		 */
		getModuleSelected(){return this.module_id;},

		getErrors(){return this.$store.state.proccess.errors;},

		getRules() {
			return {
				isRequired,
			};
		},

		getLabelBandwidth(){return BANDWIDTH;},

		getLabelBrowser(){return BROWSER;},

		getLabelBrowserVersion(){return BROWSER_VERSION;},

		getLabelCarrier(){return CARRIER;},

		getLabelDeviceMake(){return DEVICE_MAKE;},

		getLabelDeviceModel(){return DEVICE_MODEL;},

		getLabelDeviceScreenSize(){return DEVICE_SCREEN_SIZE;},

		getLabelDeviceType(){return DEVICE_TYPE;},

		getLabelOperatingSystem(){return OPERATING_SYSTEM;},

		getLabelOperatingSystemVersion(){return OPERATING_SYSTEM_VER;},

		getName(){return this.module_name;},

		getMatchingTypes(){return this.matching_types;},
		
		getMatchingTypeId(): Number{
			return this.getMatchingTypes.find(e => e.value == MATCHING_TYPE_DEFAULT)?.id;
		},
	},
	methods: {

		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("modifier_options", ["getBandwidths",
											"getBrowsers",
											"getBrowserVersions",
											"getCarriers",
											"getDeviceMakes",
											"getDeviceModels",
											"getDeviceScreenSizes",
											"getDeviceTypes",
											"getOperatingSystems",
											"getOperatingSystemVersions"]),

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		handleChangeSelection(option: string){
			if( isNull(option) ){return;}
			let entity: any = initModifierTerm();
			switch (option) {
				case BANDWIDTH:
					entity.key = "bandwidth";
					let bw = this.getAttributes(option, this.valueBandwidth);
					entity.value = bw.value;
					entity.value_name = bw.value_name;
					entity.override_multiplier = bw.override_multiplier;
					this.valueBandwidth = null;
					break;
				case BROWSER:
					entity.key = "browser";
					let b = this.getAttributes(option, this.valueBrowser);
					entity.value = b.value;
					entity.value_name = b.value_name;
					entity.override_multiplier = b.override_multiplier;
					this.valueBrowser = null;
					break;
				case BROWSER_VERSION:
					entity.key = "browser_version";
					let bv = this.getAttributes(option, this.valueBrowserVersion);
					entity.value = bv.value;
					entity.value_name = bv.value_name;
					entity.override_multiplier = bv.override_multiplier;
					this.valueBrowserVersion = null;
					break;
				case CARRIER:
					entity.key = "carrier";
					let c = this.getAttributes(option, this.valueCarrier);
					entity.value = c.value;
					entity.value_name = c.value_name;
					entity.override_multiplier = c.override_multiplier;
					this.valueCarrier = null;
					break;
				case DEVICE_MAKE:
					entity.key = "device_make";
					let dm = this.getAttributes(option, this.valueDeviceMake);
					entity.value = dm.value;
					entity.value_name = dm.value_name;
					entity.override_multiplier = dm.override_multiplier;
					this.valueDeviceMake = null;
					break;
				case DEVICE_MODEL:
					entity.key = "device_model";
					let dmodel = this.getAttributes(option, this.valueDeviceModel);
					entity.value = dmodel.value;
					entity.value_name = dmodel.value_name;
					entity.override_multiplier = dmodel.override_multiplier;
					this.valueDeviceModel = null;
					break;
				case DEVICE_SCREEN_SIZE:
					entity.key = "device_screen_size";
					let dss = this.getAttributes(option, this.valueDeviceScreenSize);
					entity.value = dss.value;
					entity.value_name = dss.value_name;
					entity.override_multiplier = dss.override_multiplier;
					this.valueDeviceScreenSize = null;
					break;
				case DEVICE_TYPE:
					entity.key = "device_type";
					let dt = this.getAttributes(option, this.valueDeviceType);
					entity.value = dt.value;
					entity.value_name = dt.value_name;
					entity.override_multiplier = dt.override_multiplier;
					this.valueDeviceType = null;
					break;
				case OPERATING_SYSTEM:
					entity.key = "operating_system";
					let osys = this.getAttributes(option, this.valueOperatingSystem);
					entity.value = osys.value;
					entity.value_name = osys.value_name;
					entity.override_multiplier = osys.override_multiplier;
					this.valueOperatingSystem = null;
					break;
				case OPERATING_SYSTEM_VER:
					entity.key = "operating_system_version";
					let osysv = this.getAttributes(option, this.valueOperatingSystemVer);
					entity.value = osysv.value;
					entity.value_name = osysv.value_name;
					entity.override_multiplier = osysv.override_multiplier;
					this.valueOperatingSystemVer = null;
					break;
			}
			if( isNull(entity.value) || isUndefined(entity.value) ){return;}
			entity.value = entity.value.toString();
			entity.module_id = this.getModuleSelected;
			entity.module_name = this.getName;
			entity.matching_type_id = this.getMatchingTypeId;
			entity.matching_type_name = MATCHING_TYPE_DEFAULT;
			this.$emit("add-term", entity);
		},

		async fetchBandwidth(params: any){
			if(isEmpty(this.dataVariable.dataBandwidth)){
				await this.setLoadingField(true);
				await this.getBandwidths(params).then((response) => {
					this.dataVariable.dataBandwidth = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchBrowsers(params: any){
			if(isEmpty(this.dataVariable.dataBrowsers)){
				await this.setLoadingField(true);
				await this.getBrowsers(params).then((response) => {
					this.dataVariable.dataBrowsers = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchBrowserVersions(params: any){
			if(isEmpty(this.dataVariable.dataBrowserVersion)){
				await this.setLoadingField(true);
				await this.getBrowserVersions(params).then((response) => {
					this.dataVariable.dataBrowserVersion = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchCarriers(params: any){
			if(isEmpty(this.dataVariable.dataCarriers)){
				await this.setLoadingField(true);
				await this.getCarriers(params).then((response) => {
					this.dataVariable.dataCarriers = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchDeviceMakes(params: any){
			if(isEmpty(this.dataVariable.dataDeviceMakes)){
				await this.setLoadingField(true);
				await this.getDeviceMakes(params).then((response) => {
					this.dataVariable.dataDeviceMakes = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchDeviceModels(params: any){
			if(isEmpty(this.dataVariable.dataDeviceModels)){
				await this.setLoadingField(true);
				await this.getDeviceModels(params).then((response) => {
					this.dataVariable.dataDeviceModels = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchDeviceScreenSizes(params: any){
			if(isEmpty(this.dataVariable.dataDeviceScreen)){
				await this.setLoadingField(true);
				await this.getDeviceScreenSizes(params).then((response) => {
					this.dataVariable.dataDeviceScreen = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchDeviceTypes(params: any){
			if(isEmpty(this.dataVariable.dataDeviceTypes)){
				await this.setLoadingField(true);
				await this.getDeviceTypes(params).then((response) => {
					this.dataVariable.dataDeviceTypes = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchOperatingSystems(params: any){
			if(isEmpty(this.dataVariable.dataOperationSystem)){
				await this.setLoadingField(true);
				await this.getOperatingSystems(params).then((response) => {
					this.dataVariable.dataOperationSystem = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		async fetchOperatingSystemVersions(params: any){
			if(isEmpty(this.dataVariable.dataOperationSystemVersion)){
				await this.setLoadingField(true);
				await this.getOperatingSystemVersions(params).then((response) => {
					this.dataVariable.dataOperationSystemVersion = response;
					this.setLoadingField(false);
				}).catch((error) => {
					this.setLoadingField(false);
				});
			}
		},

		isOverride(key: any){
			const modifierOption = this.modifiers_options[key];
			const hasOverrideMultiplier: boolean = !isUndefined(
				modifierOption?.override_multiplier
			);
			return hasOverrideMultiplier ? modifierOption.override_multiplier == "true"
				? 1
				: 0
			: undefined
		},

		getAttributes(option: any, id: Number){
			let dataAttribute = {value: undefined, value_name: undefined, override_multiplier: undefined};
			switch (option) {
				case BANDWIDTH:
					let bandw = this.dataVariable.dataBandwidth.find(e => e.id == id);
					dataAttribute.value = bandw?.extra;
					dataAttribute.value_name = bandw?.description;
					dataAttribute.override_multiplier = this.isOverride("bandwidth");
					break;
				case BROWSER:
					let browser = this.dataVariable.dataBrowsers.find(e => e.id == id);
					dataAttribute.value = browser?.extra;
					dataAttribute.value_name = browser?.description;
					dataAttribute.override_multiplier = this.isOverride("browser");
					break;
				case BROWSER_VERSION:
					let browserV = this.dataVariable.dataBrowserVersion.find(e => e.id == id);
					dataAttribute.value = browserV?.key;
					dataAttribute.value_name = browserV?.description;
					dataAttribute.override_multiplier = this.isOverride("browser_version");
					break;
				case CARRIER:
					let carrier = this.dataVariable.dataCarriers.find(e => e.id == id);
					dataAttribute.value = carrier?.extra;
					dataAttribute.value_name = carrier?.description;
					dataAttribute.override_multiplier = this.isOverride("carrier");
					break;
				case DEVICE_MAKE:
					let deviceMake = this.dataVariable.dataDeviceMakes.find(e => e.id == id);
					dataAttribute.value = deviceMake?.extra;
					dataAttribute.value_name = deviceMake?.description;
					dataAttribute.override_multiplier = this.isOverride("device_make");
					break;
				case DEVICE_MODEL:
					let deviceModel = this.dataVariable.dataDeviceModels.find(e => e.id == id);
					dataAttribute.value = deviceModel?.key;
					dataAttribute.value_name = deviceModel?.description;
					dataAttribute.override_multiplier = this.isOverride("device_model");
					break;
				case DEVICE_SCREEN_SIZE:
					let deviceScreen = this.dataVariable.dataDeviceScreen.find(e => e.id == id);
					dataAttribute.value = deviceScreen?.key;
					dataAttribute.value_name = deviceScreen?.description;
					dataAttribute.override_multiplier = this.isOverride("device_screen_size");
					break;
				case DEVICE_TYPE:
					let deviceType = this.dataVariable.dataDeviceTypes.find(e => e.id == id);
					dataAttribute.value = deviceType?.extra;
					dataAttribute.value_name = deviceType?.description;
					dataAttribute.override_multiplier = this.isOverride("device_type");
					break;
				case OPERATING_SYSTEM:
					let operatingSys = this.dataVariable.dataOperationSystem.find(e => e.id == id);
					dataAttribute.value = operatingSys?.extra;
					dataAttribute.value_name = operatingSys?.description;
					dataAttribute.override_multiplier = this.isOverride("operating_system");
					break;
				case OPERATING_SYSTEM_VER:
					let operatingSysV = this.dataVariable.dataOperationSystemVersion.find(e => e.id == id);
					dataAttribute.value = operatingSysV?.key;
					dataAttribute.value_name = operatingSysV?.description;
					dataAttribute.override_multiplier = this.isOverride("operating_system_version");
					break;
			}
			return dataAttribute;
		},

		/**
		 * Tootip data
		 * @param params
		 * @returns
		 */
		tooltipData(params: any) {
			return prepareTooltipData(params);
		},
	},
	watch: {}
});
