var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"formContent",attrs:{"justify":"center","align":"center","lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataAuctionTypes,"hint":"","label":_vm.$t('modifier.labels.exchange.auction_type.label'),"placeholder":_vm.$t('modifier.labels.exchange.auction_type.label'),"error_messages":_vm.getError('auction_type'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"description","item_value":"id"},on:{"focus":function($event){return _vm.fetchAuctionTypes()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelAuctionType)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
										_vm.$t(
											'modifier.labels.exchange.auction_type.help'
										)
									)}})]},proxy:true}]),model:{value:(_vm.valueAuctionType),callback:function ($$v) {_vm.valueAuctionType=$$v},expression:"valueAuctionType"}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[(!this.isDeliveryType)?_c('card-autocomplete',{attrs:{"items":_vm.dataVariable.dataInventorySources,"hint":"","label":_vm.$t('modifier.labels.exchange.inventory_source.label'),"placeholder":_vm.$t('modifier.labels.exchange.inventory_source.label'),"error_messages":_vm.getError('inventory_source'),"persistent_hint":false,"clearable":true,"return_object":false,"item_text":"name","item_value":"id"},on:{"focus":function($event){return _vm.fetchInventorySources()},"change":function($event){return _vm.handleChangeSelection(_vm.getLabelInventorySource)}},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_c('button-tooltip',{attrs:{"config":_vm.tooltipData(
										_vm.$t(
											'modifier.labels.exchange.inventory_source.help'
										)
									)}})]},proxy:true}],null,false,3174928160),model:{value:(_vm.valueInventorySource),callback:function ($$v) {_vm.valueInventorySource=$$v},expression:"valueInventorySource"}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }