
import Vue from "vue";
import GeoModule from "./../Modules/GeoModule.vue";
import ContentModule from "./../Modules/ContentModule.vue";
import VideoModule from "./../Modules/VideoModule.vue";
import TimeModule from "./../Modules/TimeModule.vue";
import PlatformModule from "./../Modules/PlatformModule.vue";
import UserModule from "./../Modules/UserModule.vue";
import AppSiteModule from "./../Modules/AppSiteModule.vue";
import ExchangeModule from "./../Modules/ExchangeModule.vue";
import EnvironmentModule from "./../Modules/EnviromentModule.vue";
import { isUndefined } from "lodash";

// Modules expected
const MODULE_GEO = "GEO";
const MODULE_INVENTORY = "INVENTORY";
const MODULE_PLATFORM = "PLATFORM";
const MODULE_VIDEO = "VIDEO";
const MODULE_CONTENT = "CONTENT";
const MODULE_TIME = "TIME";
const MODULE_USER = "USER";
const MODULE_APP_SITE = "APP/SITE";
const MODULE_EXCHANGE = "EXCHANGE";
const MODULE_ENVIROMENT = "ENVIRONMENT";
const TITLE = "Select a module";

export default Vue.extend({
	name: "PanelOptions",
	props: {
		optionSelected: {
			type: String,
			default: undefined,
		},
		module_id: {
			type: [Number, String],
			default: undefined,
		},
		isDeliveryModifierType: {
			type: Boolean,
			default: false,
		},
		matching_types: {
			type: Array,
			required: true,
		},
		modifiersOptions: {
			type: Object,
			default: function() {
				return {};
			}
		}
	},

	components: {
		GeoModule,
		ContentModule,
		VideoModule,
		TimeModule,
		PlatformModule,
		UserModule,
		AppSiteModule,
		ExchangeModule,
		EnvironmentModule,
	},

	data: () => ({
		title: TITLE,
	}),

	created() {},

	async mounted() {},

	computed: {
		getTitle(): String {
			return this.title;
		},

		getOptionSelected(): String {
			return this.optionSelected;
		},

		isModuleGeoSelected(): Boolean {
			let result = this.optionSelected == MODULE_GEO;
			if (result) {
				this.title = "Geo";
			}
			return result;
		},

		isModulePlatformSelected(): Boolean {
			let result = this.optionSelected == MODULE_PLATFORM;
			if (result) {
				this.title = "Platform";
			}
			return result;
		},

		isModuleVideoSelected(): Boolean {
			let result = this.optionSelected == MODULE_VIDEO;
			if (result) {
				this.title = "Video";
			}
			return result;
		},

		isModuleContentSelected(): Boolean {
			let result = this.optionSelected == MODULE_CONTENT;
			if (result) {
				this.title = "Content";
			}
			return result;
		},

		isModuleTimeSelected(): Boolean {
			let result =
				this.optionSelected == MODULE_TIME &&
				!this.getIsDeliveryModifierTypeSelected;
			if (result) {
				this.title = "Time";
			}
			return result;
		},

		isModuleUserSelected(): Boolean {
			let result = this.optionSelected == MODULE_USER;
			if (result) {
				this.title = "User";
			}
			return result;
		},

		isModuleAppSiteSelected(): Boolean {
			let result = this.optionSelected == MODULE_APP_SITE;
			if (result) {
				this.title = "App/Site";
			}
			return result;
		},

		isModuleExchangeSelected(): Boolean {
			let result = this.optionSelected == MODULE_EXCHANGE;
			if (result) {
				this.title = "Exchange";
			}
			return result;
		},

		isModuleEnvironmentSelected(): Boolean {
			let result = this.optionSelected == MODULE_ENVIROMENT;
			if (result) {
				this.title = "Environment";
			}
			return result;
		},

		getModuleId(): Number {
			return this.module_id;
		},

		getIsDeliveryModifierTypeSelected(): Boolean {
			return this.isDeliveryModifierType;
		},
	},
	methods: {

		getModifierOption(key: string) {
			return this.modifiersOptions[key];
		},


		handleAddTerm(item: any) {
			this.$emit("add-term", { ...item, actions: undefined });
		},

		handleRemoveTermTime(item: any) {
			this.$emit("remove-term-time", item);
		},

		removeTermTime(item: any) {
			this.$refs.moduleTime.removeTermTime(item);
		},

		handleGetTerm(term_name: any) {
			this.$emit("get-term", term_name);
		},

		setTerm(valuesTerm: any, term: any) {
			switch (term) {
				case "user_time_of_week":
					this.$refs.moduleTime.setTerm(valuesTerm);
					break;
				case "interstitial":
					this.$refs.moduleEnvironment.setTerm(valuesTerm);
					break;
			}
		},
	},
	watch: {
		getOptionSelected(val) {
			if(!isUndefined(val)) return;
			this.title = TITLE;
		}
	},
});
